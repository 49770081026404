// Initialisation de Select2 sur tous les éléments select
function initializeSelect2() {
    $('select').not('.select2-hidden-accessible').each(function () {
        $(this).select2({
            allowClear: true,
            placeholder: $(this).data('placeholder') || 'Sélectionnez une option', // Placeholder dynamique
            width: 'resolve' // S'assure que Select2 s'adapte à la largeur du conteneur parent
        });
    });

    // Initialisation spécifique pour les champs avec création dynamique
    $('.select2-allow-create').select2({
        tags: true,
        tokenSeparators: [','],
        placeholder: 'Sélectionnez ou créez...',
        createTag: function (params) {
            let term = $.trim(params.term);
            if (term === '') {
                return null;
            }
            return {
                id: term,
                text: term,
                newTag: true,
            };
        }
    });

    // console.log('Select2 initialized on all select elements, including dynamic creation.');
}

// Réinitialisation et application de Select2 après chaque mise à jour AJAX ou DOM
function reinitializeSelect2() {
    initializeSelect2();
    
}


// Appeler l'initialisation au chargement de la page
$(document).ready(function () {
    initializeSelect2();

    // Réappliquer Select2 après toute modification du DOM ou requête AJAX
    $(document).on('ajaxComplete MutationObserver', function () {
        reinitializeSelect2();
    });
});

// Gestion des champs Select2 avec option de création dynamique
$(document).on('select2:select', '.select2-allow-create', function (e) {
    let data = e.params.data;

    if (data.newTag) {
        let selectElement = $(this);
        let entityClass = selectElement.data('entity-class');
        let parentId = selectElement.closest('form').data('parent-id');
        let parentClass = selectElement.closest('form').data('parent-class');

        $.ajax({
            url: '/app/entity/ajaxcreate',
            type: 'POST',
            dataType: 'json',
            data: {
                entity_class: entityClass,
                designation: data.text,
                parent_class: parentClass,
                parent_id: parentId
            },
            success: function (response) {
                // Retirer l'ancien tag temporaire
                let values = selectElement.val().filter(value => value !== data.id);
                selectElement.val(values).trigger('change');

                // Ajouter la nouvelle option persistée
                let newOption = new Option(response.text, response.id, true, true);
                selectElement.append(newOption).trigger('change');
            },
            error: function () {
                alert('Erreur lors de la création.');
            }
        });
    }
});

window.initializeSliders = function () {
    
    $(".rangeprogress").ionRangeSlider({
        min: 0,
        max: 100,
        grid: true,
        step: 1
    });
    $(".range").ionRangeSlider({
        min: 0,
        max: 15,
        grid: true,
        step: 1
        //onFinish: scoreAes // Si nécessaire
    });

    console.log('Range sliders initialized.');
}


$(document).ready(function () {
    
    // Initialisation de Select2
    function initializeSelect2() {
        $('select').not('.select2-hidden-accessible').each(function () {
            $(this).select2({
                allowClear: true,
                placeholder: $(this).data('placeholder') || 'Sélectionnez une option',
                width: 'resolve'
            });
        });
        console.log('Select2 initialized.');
    }

    // Réinitialisation et application de Select2 après chaque mise à jour DOM ou AJAX
    function reinitializeSelect2() {
        initializeSelect2();
        console.log('Select2 reapplied after DOM updates or AJAX requests.');
    }

    // Gestion dynamique des boutons "ajouter"
    function addDynamicElement(buttonSelector, containerSelector, prototypeDataAttribute) {
        $(document).on('click', buttonSelector, function () {
            const container = $(containerSelector);
            const index = container.find('div.row').length;
            const tmpl = container.data(prototypeDataAttribute).replace(/__name__/g, index);
            container.append(tmpl);

            reinitializeSelect2();
            handleDeleteButtons();
        });
    }

    window.handleDeleteButtons = function() {
        $(document).on('click', 'button[data-action="delete"]', function () {
            const target = $(this).data('target');
            if (target && $(target).length) {
                $(target).remove();
                console.log(`Element ${target} removed.`);
            } else {
                console.warn(`Element ${target} not found or undefined.`);
            }
        });
    };

    
    handleDeleteButtons();

   
    
 

    // Calcul des scores AES
    function scoreAes() {
    $(".impact").each(function () {
        const id = this.id.replace('aspect_env_impacts_', '').replace('block_', '');
        const gravite = $(`#aspect_env_impacts_${id}_gravite`).val();
        const probabilite = $(`#aspect_env_impacts_${id}_probabilite`).val();
        const sensibilite = $(`#aspect_env_impacts_${id}_sensibilite`).val();
        const maitrise = $(`#aspect_env_impacts_${id}_maitrise`).val();

        const scoring = gravite * probabilite * sensibilite / maitrise || 0;
        let color, crit;
        if (scoring < 50) {
            color = "#1b998b";
            crit = "Peu important";
        } else if (scoring < 249) {
            color = "#ffe156";
            crit = "Important";
        } else if (scoring < 500) {
            color = "#F69D5C";
            crit = "Très important";
        } else if (scoring < 1000) {
            color = "#ed5861";
            crit = "Inacceptable";
        } else {
            color = "#623b5a";
            crit = "Extrême";
        }

        $(`#aspect_env_impacts_${id}_criticite`).val(scoring).css("background-color", color);
        $(`#aspect_env_impacts_${id}_critere`).val(crit);
    });


    //Ajout / Supp. des impacts
    $('#add-aesim').click(function () {
        const index = $('#aspect_env_impacts div.row').length;
        const tmpl = $('#aspect_env_impacts').data('prototype').replace(/__name__/g, index);

        $('#aspect_env_impacts').append(tmpl);
        $('select').select2()
        rangeSlider();
        handleDeleteButtons();
        scoreAes();
    });

    
    
}

$(document).ready(function () {
    // Gestion des taches et de leur mise à jour 
    // à la clôture de la tâche
    $(document).on('change', '.VarJalFini', function () {
        if ($(this).is(':checked')) {
            var confirmation = confirm("Êtes-vous sûr de marquer cette tâche comme terminée ?");
            if (confirmation) {
                // Mettre à jour la progression à 100%
                var progressionInput = $('.rangeprogress');
                if (progressionInput.length > 0) {
                    var sliderInstance = progressionInput.data("ionRangeSlider");
                    sliderInstance.update({ from: 100 });
                }

                // Décochez la case "Annulé"
                var annuleCheckbox = $('.VarJalAnnule');
                if (annuleCheckbox.length > 0) {
                    annuleCheckbox.prop('checked', false);
                }

                // Définir la date de fin réelle
                var dateFinReelleInput = $('.VarDateReelle');
                if (dateFinReelleInput.length > 0) {
                    var today = new Date().toISOString().slice(0, 10);
                    dateFinReelleInput.val(today).trigger('change');
                }
            } else {
                // Si l'utilisateur annule, décochez la case
                $(this).prop('checked', false);
            }
        } else {
            // Demander confirmation pour réouvrir la tâche
            var reopenConfirmation = confirm("Voulez-vous vraiment réouvrir cette tâche ?");
            if (reopenConfirmation) {
                // Réinitialiser la progression à 0
                var progressionInput = $('.rangeprogress');
                if (progressionInput.length > 0) {
                    var sliderInstance = progressionInput.data("ionRangeSlider");
                    sliderInstance.update({ from: 0 });
                }

                // Effacer la date de fin réelle
                var dateFinReelleInput = $('.VarDateReelle');
                if (dateFinReelleInput.length > 0) {
                    dateFinReelleInput.val('').trigger('change');
                }
            } else {
                // Si l'utilisateur annule, re-cochez la case
                $(this).prop('checked', true);
            }
        }
    });

    // Gestion de l'annulation
    $(document).on('change', '.VarJalAnnule', function () {
        if ($(this).is(':checked')) {
            var confirmation = confirm("Êtes-vous sûr de marquer cette tâche comme annulée ?");
            if (confirmation) {
                // Mettre à jour la progression à 0%
                var progressionInput = $('.rangeprogress');
                if (progressionInput.length > 0) {
                    var sliderInstance = progressionInput.data("ionRangeSlider");
                    sliderInstance.update({ from: 0 });
                }

                // Décochez la case "Fini"
                var finiCheckbox = $('.VarJalFini');
                if (finiCheckbox.length > 0) {
                    finiCheckbox.prop('checked', false).trigger('change');
                }

                // Définir la date d'annulation à aujourd'hui
                var dateAnnulationInput = $('.VarAnnuleAt');
                if (dateAnnulationInput.length > 0) {
                    var today = new Date().toISOString().slice(0, 10);
                    dateAnnulationInput.val(today).trigger('change');
                }

                // Réinitialiser la date de fin réelle
                var dateFinReelleInput = $('.VarDateReelle');
                if (dateFinReelleInput.length > 0) {
                    dateFinReelleInput.val('').trigger('change');
                }
            } else {
                // Si l'utilisateur annule, décocher la case "Annulé"
                $(this).prop('checked', false);
            }
        } else {
            // Si on décoche "Annulé", vider la date d'annulation
            var dateAnnulationInput = $('.VarAnnuleAt');
            if (dateAnnulationInput.length > 0) {
                dateAnnulationInput.val('').trigger('change');
            }
        }
    });

});


    /////////////// Workflow
$(document).on('click', '.ajaxWorkflow', function (event) {
    event.preventDefault();
    if (confirm("Voulez-vous effectuer ce changement d'état ? Un email sera envoyé pour action sur la fiche " + $(this).data('entite') + " ?")) {
        $("#overlay").fadeIn(300);
        console.log('click' + $(this).data('id') + $(this).data('statewk') + $(this).data('entite'));
        var id = $(this).data('id');
        var infostatut = $(this).data('statewk');
        var entite = $(this).data('entite');
        var pathArray = window.location.pathname.split('/');
        var domid = pathArray[2];
        var url = "/app/workflow/vdomid/vid/vstatut/ventite";
        url = url.replace("vid", id);
        url = url.replace("vstatut", infostatut);
        url = url.replace("ventite", entite);
        url = url.replace("vdomid", domid);
        console.log(url);
        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            async: true,
            success: function (response) {
                editEntite(id, entite);
                // {# $('#dataTables-'+entite).DataTable().ajax.reload(); #}
                $("#overlay").fadeOut(300);
                toastr.success(response, 'La transition a été réalisée avec succès')
            },
            error: function (xhr, textStatus, errorThrown) {
                console.log('error');
                alert('Vous ne pouvez pas accèder à cette ressource. Contactez votre administrateur.');
                $("#overlay").fadeOut(300);
            }

        });
    }
});


 /////////////// Gestion du bouton Notification depuis les fiches entités
 function setupNotifications() {
    $(document).on('click', '.ajaxNotification', function (e) {

        e.preventDefault();

        if (confirm("Voulez-vous envoyer une notification au responsable ? Un email sera envoyé pour action sur cette fiche ?")) {
            $("#overlay").fadeIn(300);

            console.log('click' + $(this).data('id') + $(this).data('entite'));

            var id = $(this).data('id');
            var entite = $(this).data('entite');
            var pathArray = window.location.pathname.split('/');
            var domid = pathArray[2];

            // Construction correcte de l'URL
            var url = '/app/notification/vdomid/vid/ventite';
            url = url.replace("vid", id);
            url = url.replace("ventite", entite);
            url = url.replace("vdomid", domid);

            console.log('URL générée :', url);

            // Appel AJAX
            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                async: true,
                success: function (response) {
                    // editEntite(id, entite);

                    $("#overlay").fadeOut(300);
                    toastr.success(response, 'La notification est envoyée');
                },
                error: function (xhr, textStatus, errorThrown) {
                    console.error('Erreur AJAX :', textStatus, errorThrown);
                    alert('Vous ne pouvez pas réaliser cette action car vous n\'avez pas sélectionné de responsable avec une adresse email');
                    $("#overlay").fadeOut(300);
                }
            });
        }
    });
}

    
    

    // Appels des fonctions nécessaires
    initializeSelect2();
    handleDeleteButtons();
    setupNotifications();


    // Gestion dynamique des champs ajoutés
    addDynamicElement('#add-objectif', '#processus_objectifs', 'prototype');
    addDynamicElement('#add-flux', '#activite_fluxConnectActivites', 'prototype');
    addDynamicElement('#add-app', '#activite_appConnectActivites', 'prototype');
    addDynamicElement('#add-act', '#application_appConnectActivites', 'prototype');
    addDynamicElement('#add-flu', '#flux_fluxConnectActivites', 'prototype');
    addDynamicElement('#add-proc', '#procedure_steps', 'prototype');
    addDynamicElement('#add-tache', '#action_jalonConnectActions', 'prototype');
    addDynamicElement('#add-nc', '#audit_nonconformites', 'prototype');
    addDynamicElement('#add-indicateur', '#objectif_indicateurs', 'prototype');
    addDynamicElement('#add-impact', '#aspect_env_impacts', 'prototype');

    // // Toggle valeur cible
    // $("input[name='objectif[suivi]']").change(toggleValeurCible);
    // toggleValeurCible(); // Initial call
});




