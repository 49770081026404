document.querySelectorAll('.switch').forEach(button => {
    button.addEventListener('click', event => {
        event.preventDefault();
        console.log('Switch clicked:', button.dataset.entite);

        const entite = button.dataset.entite;
        const trans = button.dataset.trans;
        const tdb = button.dataset.tdb;
        let domid = button.dataset.domid || window.location.pathname.split('/')[2];

        // Afficher uniquement les onglets correspondants au domid.
        document.querySelectorAll('.switchy').forEach(tab => tab.classList.add('d-none'));
        document.querySelectorAll(`.${domid}`).forEach(tab => tab.classList.remove('d-none'));

        // Réinitialisation des classes actives
        document.querySelectorAll('.switchy').forEach(el => el.classList.remove('active', 'show'));
        document.querySelectorAll('.panshowman').forEach(el => el.classList.remove('active', 'show'));

        // Activation du bon onglet et contenu
        const tabLink = document.querySelector(`.switchy[data-entite="${entite}"]`);
        const tabContent = document.querySelector(`#${entite}`);

        if (tabLink && tabContent) {
            tabLink.classList.add('active');
            tabContent.classList.add('active', 'show');
        } else {
            console.warn(`Tab or content not found for entity: ${entite}`);
        }

        // Appeler masterswitch et mettre à jour le domaine
        $.masterswitch(domid, entite, undefined, trans, tdb);
        getDomaineTitle(domid);

        // Mise à jour de l'URL
        const url = `/app/${domid}/${tdb ? 'tdb/' : ''}${entite}`;
        window.history.pushState('', '', url);

        console.log(`Switcher: ${entite} -> URL: ${url}`);
    });
});


jQuery.tabswitch = function tabSwitch(domid, entite, domaine, trans, tdb) {
    // Afficher uniquement les onglets correspondants au domid.
    document.querySelectorAll('.switchy').forEach(tab => tab.classList.add('d-none'));
    document.querySelectorAll(`.${domid}`).forEach(tab => tab.classList.remove('d-none'));

    // Réinitialisation des classes actives
    document.querySelectorAll('.switchy').forEach(el => el.classList.remove('active', 'show'));
    document.querySelectorAll('.panshowman').forEach(el => el.classList.remove('active', 'show'));

    // Activation du bon onglet et contenu
    const tabLink = document.querySelector(`.switchy[data-entite="${entite}"]`);
    const tabContent = document.querySelector(`#${entite}`);

    if (tabLink && tabContent) {
        tabLink.classList.add('active');
        tabContent.classList.add('active', 'show');
    } else {
        console.warn(`Tab or content not found for entity: ${entite}`);
    }
    $.masterswitch(domid, entite, domaine, trans, tdb);
    getDomaineTitle(domid);
};

jQuery.masterswitch = function masterSwitch(domid, entite, domaine, trans, tdb) {
    $("#overlay").fadeIn(300);

    const datatablename = `#dataTables-${entite}`;
    const url = `/app/${domid}/${tdb ? 'tdb/' : ''}${entite}`;

    if (tdb) {
        showTDB(entite, domid, url);
    } else {
        if ($.fn.dataTable.isDataTable(datatablename)) {
            const oldTable = $(datatablename).DataTable();
            const state = oldTable.state();
            oldTable.destroy();
            getDT(entite, datatablename, entite, url, state);
        } else {
            getDT(entite, datatablename, entite, url);
        }
    }

    const tabElement = document.querySelector(`#${entite}`);
    if (tabElement) {
        try {
            // Vérifiez si bootstrap et Tab sont définis
            if (typeof bootstrap !== 'undefined' && bootstrap.Tab) {
                const tabInstance = new bootstrap.Tab(tabElement);
                tabInstance.show();
            } else {
                console.warn('Bootstrap.Tab n’est pas disponible. Onglet non activé.');
            }
        } catch (error) {
            console.error(`Erreur lors de l’affichage de l’onglet pour ${entite}:`, error);
        }
    } else {
        console.warn(`Élément de l’onglet avec l’ID "${entite}" introuvable.`);
    }

    $("#overlay").fadeOut(300);
    window.history.pushState('', '', url);
};






function getDT(entite, datatablename, smname, url, state) {
    var url = url;
    $.ajax({
        type: 'GET',
        dataType: 'json',
        url: url, 
        async: true, 
        success: function(d) {
            // console.log(d.columns + 'get columns');
            //console.log(d.data + 'get data');
            // console.log("End switcher");

            // Configurer les colonnes dynamiquement
            var columns = d.columns.map(function(column) {
                // console.log('Configuring column:', column);
                // Activer SearchPanes uniquement pour 'processus' et 'responsable'
                if (column.data === 'processus' || column.data === 'responsable') {
                    column.render = {
                        _: function(data, type, row) {
                            return data;
                        },
                        sp: function(data, type, row) {
                            // Adaptation pour gérer correctement les noms dans 'responsable'
                            return type === 'sp' || type === 'filter' ? data.split(',').map(d => d.trim()) : data;
                        }
                    };
                    column.searchPanes = {
                        show: true,
                        orthogonal: 'sp'
                    };
                } else {
                    // Désactiver SearchPanes pour les autres colonnes
                    if (!column.searchPanes) {
                        column.searchPanes = { show: false };
                    }
                }
                return column;
            });
                    
            var table = $(datatablename).DataTable({
                ajax: url,
                columns: columns,
                stateSave: true,
                stateLoadCallback: function(settings) {
                    return state ? state : null; // Retourne l'état sauvegardé ou null si non disponible
                    console.log("state loaded");
                },
                
        
                pageLength: 900,
                responsive: true,
                autoWidth: false,
                colReorder: false,
                searching: true,
                searchPanes: {
                    cascadePanes: true,
                    viewTotal: true,
                    collapse: true,
                    initCollapsed: true,
                    clear: true,
                    dtOpts: {
                        language: {
                            searchPanes: {
                                emptyMessage: 'Aucune donnée', // Traduction pour "No data"
                                collapseMessage: 'Réduire tout', // Traduction pour "Collapse All"
                                showMessage: 'Montrer tout', // Traduction pour "Show All"
                                clearMessage: 'Effacer tout',
                                collapse: {0: 'Filtres de recherche', _: 'Filtres de recherche (%d)'},
                                count: '{total}',
                                countFiltered: '{shown} ({total})',
                                emptyPanes: 'Pas de filtres de recherche',
                                title: {
                                    _: 'Filtres actifs - %d',
                                    0: 'Aucun filtre actif',
                                    1: 'Un filtre actif'
                                }
                            }
                        }
                    }
                },
                select: {
                    style: 'multi',
                    selector: 'td:first-child'
                },
                order: [[ 1, 'asc' ]],
                dom: 'Pt',
                columnDefs: [ 
                    { orderable: false, targets: 'tableselect' },
                    { targets: 'no-sort', orderable: false },
                    { targets: 'no-show', visible: false, searchable: false }
                ],
                language: {
                    "decimal": "",
                    "emptyTable": "Pas de données enregistrées",
                    "loadingRecords": "Loading...",
                    "processing": "Processing...",
                    "zeroRecords": "Il n'y a pas de données correspondant à cette recherche",
                    searchPanes: {
                        clearMessage: 'Effacer tout',
                        collapse: {0: 'Filtres de recherche', _: 'Filtres de recherche (%d)'},
                        count: '{total}',
                        countFiltered: '{shown} ({total})',
                        emptyPanes: 'Pas de filtres de recherche',
                        emptyMessage: 'Aucune donnée', // Traduction pour "No data"
                        collapseMessage: 'Réduire tout', // Traduction pour "Collapse All"
                        showMessage: 'Montrer tout', // Traduction pour "Show All"
                        title: {
                            _: 'Filtres actifs - %d',
                            0: 'Aucun filtre actif',
                            1: 'Un filtre actif'
                        }
                    }
                },
                
                initComplete: function() {
                 
                    $('.sparklines').sparkline('html', { enableTagOptions: true });
                    var table = this.api();
                    $('#searchdt-' + smname).val(table.search());
                    table.on('search.dt', function() {
                        var info = table.page.info();
                        $('.rowcountview-' + smname).html(info.recordsDisplay);
                    });
                    table.on('select deselect', function() {
                        $('.rowcountselect-' + smname).html(table.rows({ selected: true }).count());
                    });
                    $("#searchdt-" + smname).on('keyup change', function() {
                        table.search(this.value).draw();
                    });
                    $(".reset").click(function() {
                        $('#searchdt-' + smname).val("");
                        table.search("").draw();
                    });
                    new $.fn.dataTable.Buttons(table, {
                        buttons: getButtonsConfig()
                    }).container().appendTo($('#buttondt-' + smname));
                    
                }
            });

            function getButtonsConfig() {
                return [
                    {
                        extend: 'collection',
                        text: '<i class="bi bi-cloud-download"></i>',
                        tag: 'button',
                        className: 'btn btn-outline',
                        buttons: [
                            {
                              extend: 'csvHtml5',
                              text: 'Export CSV',
                              exportOptions: {
                                columns: ':visible'
                              }
                            },
                            {
                              extend: 'excelHtml5',
                              text: 'Export Excel',
                              exportOptions: {
                                columns: ':visible'
                              }
                            }
                          ],
                    }
                ];
            }

            table.on("click", "th.select-checkbox", function() {
                if ($("th.select-checkbox").hasClass("selected")) {
                    table.rows().deselect();
                    $("th.select-checkbox").removeClass("selected");
                } else {
                    table.rows().select();
                    $("th.select-checkbox").addClass("selected");
                }
            }).on("select deselect", function() {
                if (table.rows({ selected: true }).count() !== table.rows().count()) {
                    $("th.select-checkbox").removeClass("selected");
                } else {
                    $("th.select-checkbox").addClass("selected");
                }
            });
        }
    });
}


function showTDB(entite, domid, url) {
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        async: true,
        success: function (data) {
            $('.page-' + entite).html('');
            $('.page-' + entite).append(data.output);
        },
        error: function (xhr, textStatus, errorThrown) {
            // console.log('Erreur');
            // console.log(status);
            // alert('Vous ne pouvez pas accèder à cette ressource. Contactez votre administrateur');

        }
    });
}

function getDomaineTitle(domid) {
    const domaines = {
        home: "Tableaux de bord GRC",
        process: "Management des processus",
        ctrl: "Audit et contrôle",
        si: "Système d'information",
        asset: "Management des assets",
        portfolio: "Pilotage du portfolio projet",
        rgpd: "Réglement général sur la protection des données",
        environnement: "Management du système environnemental",
        energie: "Management du système énergétique",
        risque: "Management des risques",
        ctrlinterne: "Contrôle interne",
        qualite: "Management de la qualité",
        qualiopi: "Référentiel National Qualité",
        mapsi_customer: "Management MAPSI",
    };

    // Utilisation de "home" comme valeur par défaut si `domid` n'existe pas dans l'objet
    const domaine = domaines[domid] || "Tableaux de bord GRC";

    $("#titledomaine").html(domaine);
    document.title = 'MAPSI - ' + domaine;
}



